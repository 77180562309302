import React, {useEffect, useRef, useState} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import {Helmet} from "react-helmet"
import CustomLink from "../../components/link";

import {confirmAlert} from "react-confirm-alert";

const axios = require("axios");
const jQuery = require("jquery");
const ContactoPage = (props) => {
const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    const {executeRecaptcha} = useGoogleReCaptcha()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setComment] = useState("")
    const [subject] = useState("Usuario desea contactar con el administrador del sitio LatamCapital");
    const [sitio] = useState("LatamCapital");
    const [bot, setLastname] = useState("")

    const send = async function (e) {
        e.preventDefault();
        if (bot === "") {
            if (!executeRecaptcha) {
                return
            }
            if (email.length === 0 || email.trim().length === 0) {
                return
            } else if (message.length === 0 || message.trim().length === 0) {
                return
            } else if (subject.length === 0 || subject.trim().length === 0) {
                return
            } else if (name.length === 0 || name.trim().length === 0) {
                return
            }
            const result = await executeRecaptcha("contacto")
            const headers = {
                "Content-Type": "application/json"
            }

            var $btn = jQuery(this).find("button[type='submit']");
            $btn.prop('disabled', true);
            $btn.html('<i class="fa fa-spinner fa-spin"></i> Enviando..');

            axios
                .post(
                    "https://us-central1-cloudsites-190318.cloudfunctions.net/mails",
                    {
                        name,
                        email,
                        message,
                        subject,
                        phone,
                        sitio,
                        token: result
                    },
                    {
                        headers: headers
                    }
                )
                .then(res => {
                    $btn.prop('disabled', false);
                    $btn.html('Contactar');
                    jQuery('#contact-form').trigger("reset");
                    confirmAlert({
                        customUI: ({onClose}) => {
                            return (
                                <div className='custom-ui'>
                                    <h1>Gracias por escribirnos</h1>
                                    <p>En breve nos pondremos en contacto con usted.</p>

                                    <button
                                        onClick={() => {
                                            onClose();
                                        }}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            );
                        }
                    });
                })

        }

    }


    return (

        <Layout location={props.location}>

            <SEO title="Contacto"/>
            <Helmet bodyAttributes={{class: "contact-page"}}/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/contacto_h.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h1 className="alt-font text-white opacity-6 margin-20px-bottom">¿Cómo te podemos
                                    ayudar?
                                </h1>
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">Contacto</h2>
                            </div>

                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 col-md-4 sm-margin-30px-bottom">
                                <h4 className="text-black font-weight-600 alt-font w-50 mb-0 ">Póngase
                                    en contacto con nosotros</h4>
                            </div>
                            <div className="col-12 col-lg-6 col-md-8">
                                <div className="row">
                                    <div className="col-12 col-sm-4 xs-margin-30px-bottom">

                                    </div>
                                    <div className="col-12 col-sm-8">

                                        <p className="w-80 margin-5px-bottom lg-w-90">Torre Optima I, Av. Paseo de las
                                            Palmas No-405. Int. 401, Lomas de Chapultepec, Miguel Hidalgo, 11000, Ciudad
                                            de México, México.</p>
                                        <span className="d-block margin-10px-bottom">Tel: +52 (55) 5202.6080</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-0 wow animate__fadeIn">
                    <div className="container">
                        <div className="row  justify-content-center">
                            <div className="col-12 col-lg-5 col-md-8 md-margin-50px-bottom">
                                <div
                                    className="feature-box feature-box-left-icon-middle padding-4-rem-all bg-light-gray overflow-hidden background-position-top background-no-repeat lg-padding-3-rem-lr md-padding-5-rem-all xs-padding-3-half-rem-all"
                                    style={{backgroundImage: 'url("images/quotes-01.png")'}}>
                                    <div
                                        className="text-large text-extra-dark-gray alt-font font-weight-500 w-90">
                                        ¿Desea conversar acerca de cómo podemos estructurar su proceso de financiamiento?
                                    </div>
                                    <p className="margin-30px-top margin-15px-bottom w-80 lg-w-100">
                                        Agende una conferencia y con gusto alguno de nuestros socios se pondrá en
                                        contacto con usted.
                                    </p>

                                </div>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 col-md-8">
                                <h4 className="alt-font text-black font-weight-600">
                                    Déjenos su mensaje
                                </h4>
                                <form onSubmit={event => send(event)} method="post" id={'contact-form'}
                                      className="alt-font text-extra-dark-gray">
                                    <input onChange={event => setName(event.target.value)}
                                           className="input-border-bottom border-color-extra-dark-gray bg-transparent placeholder-dark large-input px-0 margin-25px-bottom border-radius-0px required"
                                           type="text" name="name" placeholder="Nombre"/>
                                    <input type="text" id="lastname" name="lastname"
                                           onChange={event => setLastname(event.target.value)}
                                           style={{display: 'none'}}/>
                                    <input onChange={event => setEmail(event.target.value)}
                                           className="input-border-bottom border-color-extra-dark-gray bg-transparent placeholder-dark large-input px-0 margin-25px-bottom border-radius-0px required"
                                           type="email" name="email" placeholder="Correo electrónico"/>
                                    <input onChange={event => setPhone(event.target.value)}
                                           className="input-border-bottom border-color-extra-dark-gray bg-transparent placeholder-dark large-input px-0 margin-25px-bottom border-radius-0px"
                                           type="tel" name="phone" placeholder="Teléfono"/>
                                    <textarea
                                        onChange={event => setComment(event.target.value)}
                                        className="input-border-bottom border-color-extra-dark-gray bg-transparent placeholder-dark large-input px-0 margin-35px-bottom border-radius-0px"
                                        name="comment" rows={5} placeholder="¿Cómo podemos ayudarte?"
                                        defaultValue={""}/>
                                    <input type="hidden" name="redirect" defaultValue/>
                                    <button id={"btn-cont"} className="btn btn-medium btn-dark-gray mb-0 submit"
                                            type="submit">Enviar
                                    </button>
                                    <div className="form-results d-none"/>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="no-padding-tb wow animate__fadeIn">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 px-0">
                                <div className="map-style-3 h-500px xs-h-300px">
                                    <iframe title={'cont-mapa'} className="w-100 h-100"
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.6505426620151!2d-99.21223237080422!3d19.429587663910723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2021ffc222e31%3A0xbe991e2f329b3dfc!2sAv.%20Paseo%20de%20las%20Palmas%20404%2C%20Lomas%20-%20Virreyes%2C%20Lomas%20de%20Chapultepec%20III%20Secc%2C%20Miguel%20Hidalgo%2C%2011000%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX%2C%20Mexico!5e0!3m2!1sen!2sca!4v1620842747935!5m2!1sen!2sca"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

export default ContactoPage
